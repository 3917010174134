import React from "react"

export default function page404() {

  return (
    <div>
      NO page found
    </div>
  )
} 

